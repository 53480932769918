.actor-item {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background: white;
  padding: 1rem;
  box-shadow: 0 5px 15px hsl(0deg 0% 0% / 10%);
  height: 100%;

  &__thumb {
    position: relative;
    aspect-ratio: 3/4;
    border-radius: 1rem;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  &__infos {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    flex-direction: column;
  }

  &__title {
    color: black;
    font-size: 1.8rem;

    &--big {
      font-size: 3rem;
    }
  }

  &__subtitle {
    color: @gray;
    font-size: 1.3rem;
  }

  &__vote {
    outline: none;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
    transition: all ease 250ms;
    border: none;
    color: #fff;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 4rem;
    width: 4rem;
    left: 1rem;
    bottom: 1rem;
    z-index: 2;
    transform: translate(0, 150%);

    &--like {
      background: @green;

      i {
        animation: tada 2s linear;
      }
    }

    &--liked {
      background: @red;
    }
  }

  &__rank {
    position: absolute;
    z-index: 2;
    bottom: 1rem;
    right: 1rem;
    background: rgba(0, 0, 0, 0.8);
    padding: .5rem 1rem;
    border-radius: 1rem;
    color: white;
    display: flex;
    align-items: baseline;
    justify-content: center;

    &:before {
      font-family: 'Font Awesome 5 Pro';
      font-size: 1.5rem;
      margin-right: .5rem;
    }

    &--equal:before {
      content: '\f52c';
      font-size: 1rem;
    }

    &--down:before {
      content: '\f309';
      color: @red;
    }

    &--up:before {
      content: '\f30c';
      color: @green;
    }
  }

  &:hover {
    .actor-item {
      &__vote {
        transform: translate(0%, 0%);
      }
    }
  }

  @media screen and (max-width: 920px) {
    &__title--big {
      font-size: 1.8rem;
    }
  }
}
