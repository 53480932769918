@import (less) "../../fonts/fontawesome/less/brands.less";
@import (less) "../../fonts/fontawesome/less/duotone.less";
@import (less) "../../fonts/fontawesome/less/fontawesome.less";
@import (less) "../../fonts/fontawesome/less/light.less";
@import (less) "../../fonts/fontawesome/less/regular.less";
@import (less) "../../fonts/fontawesome/less/solid.less";
@import (less) "../../fonts/fontawesome/less/v4-shims.less";

/* Light */

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/light/ubuntu-light-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/light/ubuntu-light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/light/ubuntu-lightitalic-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/light/ubuntu-lightitalic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

/* REGULAR */

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/regular/ubuntu-regular-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/regular/ubuntu-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/regular/ubuntu-italic-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/regular/ubuntu-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* MEDIUM */

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/medium/ubuntu-medium-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/medium/ubuntu-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/medium/ubuntu-mediumitalic-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/medium/ubuntu-mediumitalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* BOLD */

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/bold/ubuntu-bold-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/bold/ubuntu-bold-webfont.woff') format('woff');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/Ubuntu/bold/ubuntu-bolditalic-webfont.woff2') format('woff2'),
  url('../../fonts/Ubuntu/bold/ubuntu-bolditalic-webfont.woff') format('woff');
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}
