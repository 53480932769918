.cta-group {
  display: flex;
  flex-wrap: wrap;

  .cta {
    margin: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }

  &--fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 3;
    box-shadow: 0 5px 15px rgb(0 0 0 / 20%), 0 15px 30px rgb(0 0 0 / 15%);
    width: 100%;
    background: white;
    padding: 1.5rem;
    display: flex;
    justify-content: center;

    .cta {
      margin: 0 1rem;
      padding: 1rem 3rem;
    }
  }
}

.cta {
  background: transparent;
  border: none;
  padding: 1rem;
  font-size: 1.5rem;
  border-radius: .5rem;
  display: block;
  text-decoration: none;
  text-align: center;

  &__icon {
    margin-right: 1rem;
  }

  &--main-color {
    background-color: var(--main-color);
    color: white;
  }

  &--gray {
    background-color: @gray;
    color: white;
  }

  &--background-green {
    background: @green;
    color: white;
    border: 2px solid @green;
  }

  &--background-dark-green {
    background: @dark-green;
    color: white;
    border: 2px solid @dark-green;
  }

  &--border-green {
    border: 2px solid @green;
    color: @green;

    small {
      color: white;
    }
  }

  > * {
    pointer-events: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }

  i {
    margin-right: 1rem;
  }

  small {
    display: block;
    font-weight: normal;
  }
}

.cta--straceo {
  padding: 10px 30px;
  border-radius: 5px;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
  position: absolute;
  z-index: 22;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
  font-size: 16px;
  background: #41cf29;
  color: white;
  border: 2px solid #41cf29;
  width: 80%;
}
