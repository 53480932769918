.article-item {
  margin-bottom: 2rem;

  &__content {
    border: 1px solid @gray-90;
    height: 100%;
    background: white;
    border-radius: 1rem;
    overflow: hidden;
    display: block;
    transition: all ease 250ms;
    transition-delay: 50ms;

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }

  &__thumb {
    position: relative;
    overflow: hidden;
    aspect-ratio: 4/3;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__date {
    font-size: 1.3rem;
    color: @gray;
  }

  &__infos {
    padding: 1rem;
    font-weight: normal;
  }

  &__title {
    font-weight: normal;
    font-size: 1.5rem;
    color: var(--main-color);
  }
}
