.article {
  margin: 0 auto;
  max-width: 100rem;

  &__cover {
    display: block;
    position: relative;
    aspect-ratio: 3/1;
    margin-bottom: 1rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  &__see-more {
    margin-top: 2rem;
    border-top: 1px solid #f2f2f2;
    position: relative;

    p {
      text-align: center;
      padding: 2rem;
    }


    &:before {
      content: '';
      width: 100%;
      height: 100%;
      max-height: 6rem;
      display: block;
      background: linear-gradient(0deg, white, transparent);
      position: absolute;
      top: -1px;
      left: 0;
      transform: translateY(-100%);
    }
  }


}
