.carousel-banner {
  &__item {
    aspect-ratio: 4/1;
    position: relative;
    background: @gray-95;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  &--small {
    .carousel-banner__item {
      border-radius: .5rem;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 920px) {
    &__item {
      aspect-ratio: 27/22;
    }
  }
}


.carousel-controls__btn {
  position: absolute;
  top: 50%;
  width: 6rem;
  height: 6rem;
  background: var(--main-color);
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1rem solid white;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }

  &--prev {
    left: 0;
    transform: translate(-50%, -50%);
  }

  &--next {
    right: 0;
    transform: translate(50%, -50%);
  }

  @media screen and (max-width: 1500px) {
    &--prev {
      left: 5rem;
      transform: translate(-50%, -50%);
    }

    &--next {
      right: 5rem;
      transform: translate(50%, -50%);
    }
  }


  @media screen and (max-width: 910px) {
    display: none;
  }
}
