.actor-list {
  &--carousel {
    z-index: 2;
    position: relative;
    padding: 2rem 0;

    .gl__col {
      display: none;
    }

    &.tns-slider {
      .gl__col {
        display: block;
        padding: 0;
      }
    }
  }

  @media screen and (min-width: 920px) {
    &--carousel {
      .gl__col {
        &:nth-child(-n+5) {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    &--carousel {
      .gl__col {
        &:nth-child(-n+3) {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &--carousel {
      .gl__col {
        display: none;

        &:nth-child(-n+2) {
          display: block;
        }
      }
    }
  }
}
