.highlight-video {
  &__header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }

  &__title {
    font-size: 3rem;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    line-height: 1.2;
    color: #333;
  }

  &__subtitle {
    color: var(--main-color);
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1;
  }

  &__player {
    display: block;
    position: relative;
    aspect-ratio: 5/3;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  &__play {
    font-size: 3rem;
    display: flex;
    background: var(--main-color);
    border-radius: 50%;
    line-height: inherit;
    box-shadow: 0 1px 10px 1px rgb(0 0 0 / 30%);
    color: #fff;
    width: 8rem;
    height: 8rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    .translate(-50%, -50%);
    z-index: 1;
  }

  &__content {
    display: grid;
    grid-template-columns: 60% 1fr;
    max-width: 110rem;
    margin: 0 auto;
    grid-gap: 2rem;
    align-items: center;
  }

  &__ribbon {
    position: absolute;
    background: #4bc84b;
    padding: 1rem;
    z-index: 1;
    color: white;
    border-radius: 1rem;
    top: 1rem;
    left: 1rem;
  }

  &__poster {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__scene-title {
    font-size: 2.5rem;
    font-weight: normal;
    padding-bottom: 2rem;
    line-height: 1;

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 3px;
      background-color: #da002f;
      margin-top: 2rem;
    }
  }

  &__info {
    margin-bottom: 1rem;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    .cta {
      margin-top: 2rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: normal;
    }

    a {
      color: var(--main-color);
    }

    i {
      color: var(--main-color);
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 920px) {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}

.cta {
  display: block;
  text-align: center;
  padding: 1rem;
  border-radius: .5rem;
}

.cta--background-main {
  background: var(--main-color);
  color: white;
}
