.settings-navbar {
  padding: 2rem 0;
  list-style: none;
  background: white;
  border-radius: 1rem;

  &__item {
    padding: 1rem 2rem;
  }

  &__link {
    font-size: 1.5rem;
    color: @gray;
    .transition(all ease 500ms);
    display: block;
    text-decoration: none;
    position: relative;

    .fas {
      margin-right: 1rem;
      color: var(--main-color);
    }

    span {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 1rem;
      opacity: 0.5;
    }

    &:hover, &:focus, &active {
      color: @black;
    }
  }

  @media screen and (max-width: 920px) {
    padding: 1rem 0;
    margin-bottom: 1rem;

    &__item {
      padding: .5rem 2rem;
    }
  }
}
