@base-grid-col: 12;

.gl {
  --container-width: 1440px;

  &__container {
    width: var(--container-width);
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }

  &__row {
    --gap-between-col: 2rem;

    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .row-loop(@base-grid-col; ~"");

    @media screen and (max-width: 920px) {
      --gap-between-col: 1rem;
    }

    @media screen and (min-width: 920px) {
      .row-loop(@base-grid-col; -sm);
    }

    @media screen and (min-width: 1280px) {
      .row-loop(@base-grid-col; -md);
    }

    &--inline {
      flex-wrap: nowrap;
    }

    .gl {
      &__col {
        .col-loop(@base-grid-col; ~'');

        @media screen and (max-width: 920px) {
          .col-loop(@base-grid-col; -xs);
        }

        @media screen and (min-width: 920px) {
          .col-loop(@base-grid-col; -sm);
        }

        @media screen and (min-width: 1280px) {
          .col-loop(@base-grid-col; -md);
        }

        &--margin {
          margin-bottom: var(--gap-between-col);
        }
      }
    }
  }

  &__col {
    flex: 1 0 0%;
  }
}

[class^="gl__col"] {
  padding-left: calc(var(--gap-between-col) * .5);
  padding-right: calc(var(--gap-between-col) * .5);
}


@media screen and (min-width: 920px) {
  .visible-xs {
    display: none !important;
  }
}

@media screen and (max-width: 920px) {
  .hidden-xs {
    display: none !important;
  }
}


// Set grid size from row

.row-loop(@iteration; @col-type) when not(@iteration=0) {
  &--col@{col-type}-@{iteration} {
    .gl__col {
      flex: 0 0 auto;
      width: calc(100% / @iteration);
    }
  }
  .row-loop((@iteration - 1); @col-type);
}

;

// Set grid size from col

.col-loop(@iteration; @col-type) when not(@iteration=0) {
  &@{col-type}-@{iteration} {
    width: calc((100% / @base-grid-col) * ~"@{iteration}");
    flex: 0 0 auto;
  }
  .col-loop((@iteration - 1); @col-type);
}

;
