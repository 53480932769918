.magazine-detail {
  display: grid;
  grid-row-gap: 1rem;
  max-width: 100rem;
  margin: 0 auto;
  width: 100%;

  &__content {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 2rem;

    &__right {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-gap: 1rem;
    }
  }

  &__infos {
    display: grid;
    grid-template-columns: 30% 1fr;
    padding: 2rem;
    background: #f3f3f3;
    border-radius: .5rem;
    grid-gap: 2rem;
  }

  &__title {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
  }

  &__subtitle {
    font-size: 1.5rem;
    color: @gray;
    font-weight: normal;
  }

  &__header {
    margin-bottom: 2rem;

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 5px;
      background-color: var(--main-color);
      margin-top: 1rem;
    }
  }

  &__cover {
    height: 0;
    padding-bottom: calc(100% / (5 / 7));
    width: 100%;
    position: relative;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    display: block;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
      object-fit: cover;
    }
  }

  &__ctas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem -0.5rem;

    .cta {
      padding: 1rem 3rem;
      margin: 0 0.5rem;
      width: 100%;
    }
  }

  &__majority {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-radius: 1rem;
    border: 1px solid #21ad7b;
    background: rgba(33, 173, 123, 0.2);


    i {
      color: #ffffff;
      font-size: 2rem;
      background: #21ad7b;
      padding: 1rem;
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    p {
      margin: 2rem 0;
    }

    .cta {
      background: #21ad7b;
      color: white;
      padding: 1rem 3rem;
      font-weight: bold;
      border-radius: 1rem;
    }
  }

  @media screen and (max-width: 920px) {
    &__content {
      grid-template-columns: 1fr;
      width: 100%;
    }

    &__list {
      grid-template-columns: 1fr 1fr;
    }

    &__left,
    &__right {
      display: contents;
    }

    &__header {
      order: -1;
      margin-bottom: 0;
    }

    &__ctas {
      display: grid;
      width: 100%;
      grid-gap: 1rem;
      margin: 0;
      justify-content: unset;
      position: sticky;
      bottom: 0;
      background: white;
      padding: 1rem;

      .cta {
        margin: 0;
      }
    }
  }
}
