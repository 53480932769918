.navigation {
  position: relative;
  z-index: 3;
  background: white;

  &__top {
    background: white;
    border-bottom: 1px solid @gray-90;
    padding: .5rem;
  }

  &__close {
    display: none;
  }

  &__content {
    width: var(--container-width);
    max-width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    &--right {
      justify-content: flex-end;
    }
  }

  &__right {
    display: flex;
  }

  &__item {
    display: flex;
  }

  &__sublink {
    border-left: 1px solid @gray-90;
    padding: .5rem 1.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    color: black;

    &--colored {
      color: var(--main-color);
    }

    &--icon {
      color: @gray-80;

      i {
        font-size: 1.8rem;
      }

      &:hover {
        color: var(--main-color);
      }
    }
  }

  &__search {
    display: flex;

    &__input {
      padding: 0;
      border: none;
      text-indent: 1rem;
      width: 0;
      transition: width ease 250ms;
      transform-origin: right center;
    }

    &__input:focus,
    &__input:valid,
    &:hover .navigation__search__input {
      width: 15rem;
    }
  }


  &__logo {
    display: block;
    padding: 1rem 0;
    width: 6rem;
    max-width: 60vw;

    img {
      width: 100%;
      display: block;
    }
  }

  &__main {
    border-bottom: 1px solid @gray-90;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem clamp(1rem, 1.2vw, 2rem);
    color: black;
    font-size: clamp(1.2rem, 1.5vw, 1.6rem);

    &:not(.navigation__link--background):hover {
      background: @gray-90;
    }

    &--background {
      background: var(--main-color);
      color: white;
    }
  }

  &__open {
    display: none;
  }

  &__mobile {
    display: contents;
  }
}

@media screen and (max-width: 920px) {
  .navigation {
    display: contents;

    &__content {
      &--right {
        padding: 0;
      }
    }

    &__main {
      position: sticky;
      top: 0;
      z-index: 19;
      background: white;
    }

    &__logo {
      width: 4rem;
    }

    .navigation__left {
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;
    }

    &__open {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 7px;
      padding: 1rem;

      &__bar {
        width: 3rem;
        background: var(--main-color);
        height: 3px;
        display: block;
        border-radius: 1rem;

        &--short {
          width: 20px;
          margin-left: auto;
        }
      }
    }

    &__mobile {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);

      .navigation__right {
        width: calc(100% - 10rem);
        background: #313131;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        padding: 0;
        overflow: auto;
      }

      &--open {
        display: block;
      }

    }

    &__link {
      color: white;
      font-size: 1.5rem;
      padding: 1rem 2rem;
      width: 100%;
      text-align: left;
      justify-content: flex-start;

      &:not(.navigation__link--background):hover {
        background: #3a3939;
      }

      &--background {
        .navigation__link__icon {
          color: white;
        }
      }

      &__icon {
        color: var(--main-color);
        margin-right: 1rem;
      }
    }

    &__search {
      padding: 1rem 2rem;
      width: 100%;

      &__input {
        width: 100% !important;
        padding: 1rem;
        border-radius: 1rem;
      }

      button {
        border: none;
      }
    }

    &__close {
      position: absolute;
      width: 3rem;
      height: 3rem;
      right: 1rem;
      top: 1rem;
      padding: 0;
      background: transparent;
      display: block;


      &__bar {
        background: #313131;
        height: .5rem;
        width: 100%;
        display: block;
        border-radius: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;

        &:first-child {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}
