.actor-detail {
  display: grid;
  grid-row-gap: 1rem;
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;

  &__content {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 2rem;

    &__right {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-gap: 1rem;
    }
  }

  &__infos {
    display: grid;
    grid-template-columns: 30% 1fr;
    padding: 2rem;
    background: #f3f3f3;
    border-radius: .5rem;
    grid-gap: 2rem;
  }

  &__title {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
  }

  &__subtitle {
    font-size: 1.5rem;
    color: @gray;
    font-weight: normal;
  }

  &__header {
    margin-bottom: 2rem;

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 5px;
      background-color: var(--main-color);
      margin-top: 1rem;
    }
  }

  &__cover {
    height: 0;
    padding-bottom: calc(100% / (5 / 7));
    width: 100%;
    position: relative;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    display: block;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
      object-fit: cover;
    }
  }

  &__description {
    display: grid;
    grid-row-gap: 1rem;

    &__title {
      color: var(--main-color);
      text-transform: uppercase;
      font-size: 2rem;
    }
  }

  &__like {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    z-index: 2;

    &__link {
      background: #16da5f;
      width: 100%;
      height: 100%;
      color: white;
      border-radius: 3rem;
      padding: 1rem;

      &:after {
        content: attr(data-text);
        font-weight: bold;
        margin-left: 1rem;
      }

      &--ok {
        &:after {
          content: attr(data-text-ok);
        }
      }

      &--error {
        background: @red;

        &:after {
          content: attr(data-text-error);
        }
      }
    }
  }

  &__list {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;

    &-item {
      padding-left: 3rem;
      position: relative;

      &__icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: var(--main-color);
        font-size: 1.5rem;
      }

      &__label {
        display: block;
        font-size: 1.2rem;
        color: gray;
      }

      &__content {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1;
        display: block;
        margin-bottom: 1rem;

        &--link {
          color: var(--main-color);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__ctas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem -0.5rem;

    .cta {
      padding: 1rem 3rem;
      margin: 0 0.5rem;
      width: 100%;
    }
  }

  @media screen and (max-width: 920px) {
    &__content {
      grid-template-columns: 1fr;
      width: 100%;
    }

    &__list {
      grid-template-columns: 1fr 1fr;
    }

    &__left,
    &__right {
      display: contents;
    }

    &__header {
      order: -1;
      margin-bottom: 0;
    }
  }
}
