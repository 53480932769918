// Vendors

@import "../app/lesshat/build/lesshat";
@import "../fonts/fontawesome/less/_variables";

@import (css) "../../webpack/node_modules/tiny-slider/dist/tiny-slider.css";

// mixins

@import "mixins/_colors";
@import "mixins/_animations";

// Base

@import "base/_reset";
@import "base/_typography";
@import "base/_init";

// Utils

@import "utils/_grid-layout";
@import "utils/_pub";
@import "utils/_carousel-banner";
@import "utils/_seo";
@import "utils/_cta";
@import "utils/_captcha";
@import "utils/_plq";


@import "components/_main";
@import "components/_content-list";
@import "components/_actor-list";
@import "components/_widget-comeback";
@import "components/_widget-head";
@import "components/_widget-offer";

@import "components/_navigation";
@import "components/_footer";
@import "components/_payment";
@import "components/_disclaimer";
@import "components/_pagination";
@import "components/_filters";
@import "components/_userstate";

@import "components/_settings";

@import "components/_actor-item";
@import "components/_video-item";
@import "components/_scene-item";
@import "components/_article-item";

@import "components/_forms";

@import "components/_home-tags";
@import "components/_dropdown";
@import "components/_highlight-video";

@import "components/_dalenys-modal";

@import "pages/_video-detail";
@import "pages/_article-detail";
@import "pages/_magazine-detail";
@import "pages/_magazine-viewer";
@import "pages/_actor-detail";
