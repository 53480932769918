:root {
  --main-color: @red;
  --dark-main-color: darken(@red, 10%);
  --container-width: 1440px;
}

html {
  font-family: 'Ubuntu', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 62.5%;
  height: 100%;
  color: @black;
}

body {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem; /* =15px */
  background-color: white;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;

  &.has-cta-bottom {
    footer {
      padding-bottom: 7rem;
    }
  }
}

//.nsfw {
//    filter: blur(10px);
//}
