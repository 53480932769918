.magazine-viewer {
  &__pagination {
    display: grid;
    grid-template-columns: 5rem auto 5rem;
    background: #eeeeee;
    border-radius: 1rem;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    input {
      border: none;
      padding: 1rem;
      min-width: 0;
      background: transparent;
      width: 100%;
      text-align: center;
      font-family: 'Ubuntu', sans-serif;
      font-size: 1.5rem;
      line-height: 1;
    }

    [data-page-count] {
      padding: 1rem;
      font-family: 'Ubuntu', sans-serif;
      font-size: 1.5rem;
      line-height: 1;
      text-align: center;
    }
  }

  &__controls {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto auto;
    grid-gap: 1rem;
    padding: 1rem 0;
    cursor: pointer;
  }

  &__button {
    padding: 1.5rem 2rem;
    background: var(--main-color);
    color: white;
    border-radius: 1rem;
    min-width: 10rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 250ms;

    &[disabled]{
      opacity: .2;
      background:@gray-80;
      color:black;
    }

    &--prev:before {
      content: '\f104';
      margin-right: 1rem;
      font-family: 'Font Awesome 5 Pro';
    }

    &--next:after {
      content: '\f105';
      margin-left: 1rem;
      font-family: 'Font Awesome 5 Pro';
    }

    &:hover:not([disabled]) {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07), 0px 15px 35px rgba(0, 0, 0, 0.05);
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 5rem;
  }

  &__preview {
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15), 0 15px 35px rgba(0, 0, 0, 0.1);
    display: flex;
    min-height:15rem;
    min-width: 25%;
    position: relative;
  }

  canvas {
    display: block !important;
    aspect-ratio: 21 / 29.7;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px);
    display: none;
    z-index: 2;
    padding: 1rem;

    &--show {
      display: block;
    }

    &__cta {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      text-align: center;
      font-size: 1.5rem;

      .cta {
        margin-top: 1rem;
        padding: 1rem 2rem;
      }
    }
  }

  &__cta {
    padding: 1rem 2rem;
    background: #eeeeee;
    margin: 0 .5rem;
    border-radius: 1rem;
    color: #0d0d0d;

    &--green {
      background: @green;
      color: white;
    }
  }


  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px);
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;

    &--hidden {
      display: none;
    }
  }

  &__ctas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 920px) {
    &__controls {
      width: 100%;
      padding: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__preview {
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0;
    }

    &__button {
      font-size: 0;
      min-width: auto;

      &:before,
      &:after {
        font-size: 2rem;
        margin: 0;
      }
    }

    &__ctas {
      width: 100%;
      order: 2;
      padding: 0 1rem;
    }

    &__cta {
      width: 100%;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__nav {
      position: fixed;
      bottom: 0;
      z-index: 3;
      width: 100%;
      background: white;
    }
  }
}
