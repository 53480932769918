.home-tags {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  grid-gap: 1rem;

  &__item {
    position: relative;
    aspect-ratio: 1;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: black;

    &:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
      aspect-ratio: 1/2;
    }

    &:nth-child(3) {
      grid-column-start: 3;
      grid-column-end: 5;
      aspect-ratio: 2/1;
    }

    &:nth-child(4) {
      grid-column-start: 5;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 3;
      aspect-ratio: 1/2;
    }

    &:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
      aspect-ratio: 2/1;
    }

    &:nth-child(6) {
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &:hover {
      .home-tags {
        &__img {
          .scale(1.1);
        }
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: none;
    opacity: .8;
    transition: all ease 500ms;
  }

  &__title {
    color: white;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    font-size: 2rem;
  }

  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);

    &__title {
      text-align: center;
      line-height: 1;
    }

    &__item {
      border-radius: .5rem;

      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      &:nth-child(3) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        aspect-ratio: 1;
      }

      &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
        aspect-ratio: 1;
      }

      &:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
        aspect-ratio: 2/1;
      }

      &:nth-child(6) {
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 2;
        grid-column-end: 3;
      }
    }
  }

}
