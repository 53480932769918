.content-list {
  &__header {
    display: flex;
    align-items: baseline;
    margin-bottom: 3rem;
    flex-direction: column;
    position: relative;
    padding-bottom: 2rem;

    &--split {
      justify-content: space-between;
      flex-direction: row;
    }

    &__left {
      display: flex;
      align-items: baseline;
      flex-direction: column;
    }

    &:before {
      content: '';
      height: .5rem;
      width: 10rem;
      background-color: var(--main-color);
      position: absolute;
      top: 100%;
      left: 0;
    }

    &--white {
      .content-list {
        &__title {
          color: white;
        }

        &__subtitle {
          color: white;
          opacity: .8;
        }
      }
    }
  }

  &__content {
    position: relative;
  }

  &__title {
    margin: 0;
    line-height: 1;
    text-transform: uppercase;
    font-size: 4rem;
    letter-spacing: 1px;
    color: @black;
    position: relative;
    font-weight: 500;

    &--small {
      text-transform: initial;
      font-size: 2.5rem;
    }
  }

  &__subtitle {
    color: #bbbbbb;
    font-weight: normal;
  }

  &__see-more {
    color: var(--main-color);
    font-size: 1.8rem;
    padding: 0;
    font-weight: 400;
  }

  &__custom-layout {
    display: grid;
    grid-gap: var(--gap-between-col);
    grid-template-columns: repeat(6, 1fr);

    .gl__col {
      padding: 0;

      .video-item {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }

  &__empty {
    text-align: center;
    font-weight: bold;
  }

  @media screen and (max-width: 910px) {
    &__header {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      &__left {
        a {
          display: none;
        }
      }

      &--split {
        flex-direction: column;
      }

      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__title {
      font-size: 2rem;
      width: 100%;
      text-align: center;

      &:before {
        display: none;
      }
    }

    &__subtitle {
      text-align: center;
    }

    &__see-more {
      text-align: center;
      border: 1px solid var(--main-color);
      border-radius: 2rem;
      padding: 0.25rem 2rem;
      font-size: 1.5rem;
      margin-top: 1rem;
    }

    &__custom-layout {
      grid-template-areas: unset;
      grid-template-columns: 1fr 1fr;

      .gl__col {
        &:nth-child(1) {
          grid-area: unset;

          .video-item {
            padding-bottom: calc((390 / 280) * 100%);
          }
        }

        &:last-child {
          grid-column-end: 3 !important;
          grid-column-start: 1 !important;
        }
      }
    }

    .video-item {
      &--last {
        padding-bottom: calc((390 / 280) * 25%);
        aspect-ratio: unset;

        .video-item__thumb {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
