.dropdown {
  position: relative;
  z-index: 1;

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    min-width: 160px;
    font-size: 14px;
    text-align: left;
    list-style: none;
    display: none;

    &--right {
      left: auto;
      right: 0;
    }

    &--top {
      bottom: 100%;
      top: auto;
      right: 0;
      margin-bottom: .5rem;
      margin-top: 0;
    }

    @media screen and (max-width: 768px) {
      width: 100%;

      &--mobile-left {
        left: 0;
        right: 0;
      }
    }
  }

  &__toggle {
    cursor: pointer;

    &--carret {
      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .5rem;
        vertical-align: middle;
        border-top: .5rem dashed;
        border-right: .5rem solid transparent;
        border-left: .5rem solid transparent;
      }
    }
  }

  &--open {
    .dropdown {
      &__content {
        display: flex;
      }

      &__toggle {
        &--carret {
          &:after {
            border-top: 0;
            border-bottom: .5rem dashed;
            border-right: .5rem solid transparent;
            border-left: .5rem solid transparent;
          }
        }
      }
    }
  }
}

.dropdown-user {
  &__content {
    background: white;
    width: 100%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
    padding: .5rem 0;
    border-radius: 1rem;
    max-height: 20rem;
    overflow: auto;
  }

  &__link {
    display: block;
    padding: .5rem 1rem;
    color: black;

    &--colored {
      color: var(--main-color);
    }

    &:hover {
      background: #f2f2f2;
    }

    i {
      margin-right: 1rem;
      color: var(--main-color);
    }
  }
}

.dropdown-download {
  &__content {
    width: 100%;
    max-height: 25rem;
    overflow: auto;
    background: @green;
    border: none;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    margin-top: 1rem;
  }

  &__link {
    font-size: 1.2rem;
    padding: 1rem;
    color: @white;
    display: block;
    position: relative;

    &:before {
      content: @fa-var-download;
      font-family: 'font awesome 5 pro';
      font-weight: 400;
      margin-right: 1rem;
    }

    &:hover {
      background: @dark-green;
    }
  }
}

.dropdown-tags {
  &__content {
    background: white;
    width: 100%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
    padding: .5rem 0;
    border-radius: 1rem;
    max-height: 20rem;
    overflow: auto;
  }

  &__link {
    display: block;
    padding: .5rem 1rem;
    color: black;

    &--colored {
      color: var(--main-color);
    }

    &:hover {
      background: #f2f2f2;
    }
  }

  @media screen and (max-width: 920px) {
    flex-direction: column;

    .dropdown__content {
      position: relative;
      top: 0;
      padding: 0 1rem;
    }
  }
}
