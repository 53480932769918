.payment {
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: center;

  &__backdrop {
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__subtitle {
    color: @gray;
  }

  &__content {
    position: relative;
    z-index: 2;
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    max-width: 100%;
    display: grid;
    grid-row-gap: 2rem;
    width: 50rem;
  }

  &__title {
    font-size: 2rem;
  }

  &__offers {
    display: none;
    grid-gap: 1rem;

    &--show {
      display: grid;
    }
  }

  &--show {
    display: flex;
  }
}


.offer {
  position: relative;
  cursor: pointer;

  &__input {
    display: none;
  }

  &__icon {
    position: absolute;
    left: 2rem;
    font-size: 2rem;
    top: 50%;
    opacity: .3;
    transform: translateY(-50%);
  }

  &__input:checked ~ .offer__label {
    background: @green;
    border: 1px solid @green;
    color: white;

    .offer {
      &__tag {
        background: rgb(0 0 0 / 10%);
      }

      &__subtitle,
      &__list-item,
      &__icon {
        opacity: 1;
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border: 1px solid @gray-85;
    border-radius: 1rem;
    align-items: flex-start;
    color: black;
    cursor: pointer;

    &:hover {
      border: 1px solid @green;
      background: fadeOut(@green, 80%);
    }

    &--icon {
      padding-left: 6rem;
      position: relative;
    }

    &--split {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }

    &--highlight {
      background: @green;
      color: white;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 1.3rem;
    opacity: .5;
  }

  &__list {
    margin-top: .5rem;
    display: grid;
    padding-left: .25rem;

    &-item {
      list-style: initial;
      font-weight: 400;
      font-size: 1.3rem;
      opacity: .5;

      &:before {
        content: '•';
        margin-right: 1rem;
      }
    }
  }

  &__tag {
    font-size: 1.2rem;
    background: @green;
    color: white;
    padding: .5rem 1rem;
    border-radius: 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__top {
      font-size: 3rem;
      font-weight: bold;
      line-height: 1;
    }

    &__bottom {
      font-size: 1.2rem;
      color: #c1c1c1;
    }
  }
}

@media screen and (max-width: 920px) {
  .payment {
    align-items: flex-end;

    &__content {
      border-radius: 0;
      overflow: auto;
      max-height: calc(100% - 5rem);
    }

    &__offers {
      &--custom {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        .payment__item:nth-child(1) {
          grid-column: auto;
        }
      }
    }
  }

  .offer {
    &__tag {
      position: relative;
      order: -1;
      display: block;
      top: auto;
      right: auto;
      margin-bottom: 0.5rem;
    }

    &__icon {
      left: 1rem;
    }

    &__label {
      padding: 1rem;

      &--icon {
        padding-left: 4rem;
      }
    }
  }
}
