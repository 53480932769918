/* DISCLAIMER ----------------------------------------------- */

.disclaimer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  background-color: white;

  --disclaimer-color: var(--main-color);
  --disclaimer-text: rgba(0,0,0,0.5);
  --disclaimer-title: black;
  --disclaimer-border: rgba(0,0,0,0.1);

  &__container {
    display: grid;
    grid-template-columns: 40% 1fr;
    height: 100%;
  }

  &__title {
    font-size: 25px;
    margin: 0;
    color: var(--disclaimer-title);
    strong {
      color: var(--disclaimer-color);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 50px;
    justify-content: center;
    gap: 20px;
  }

  &__pictures {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left center;
    }
  }

  &__more {
    border: 1px solid var(--disclaimer-border);
    padding: 20px;
    height: 100px;
    overflow: auto;
    flex: 1;
    max-height: 200px;
    text-align: left;
    border-radius: 10px;
    display: grid;
    grid-gap: 10px;
  }

  &__logo {
    text-align: center;
    max-width: 250px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--disclaimer-title);
  }

  &__text-title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: var(--disclaimer-title);
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    line-height: 18px;
    color: var(--disclaimer-text);

    a {
      color: var(--disclaimer-title);
      font-weight: 700
    }
  }

  &__btns {
    width: 100%;
    text-align: center;
    align-self: center;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
  }

  &__btn {
    color: var(--disclaimer-title);
    display: block;
    position: relative;
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 18px;

    &--enter {
      text-decoration: none;
      text-underline: none;
      transition: ease-in-out .3s;
      background-color: var(--disclaimer-color);
      color: white;
      font-weight: bold;
    }

    &--exit {
      border: 1px solid var(--disclaimer-border);
      color: var(--disclaimer-title);;
    }
  }

  &__desktop {
    display: block;
  }

  &__mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .disclaimer {
    overflow: auto;
    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }

    &__container {
      grid-template-columns: 1fr;
      grid-template-rows: 50vh 1fr;
    }

    &__pictures {
      grid-row-start: 1;
      grid-row-end: 2;

      img {
        object-position: bottom center;
      }
    }

    &__content {
      padding: 0 15px 15px 15px;
      margin-top: -30px;
      gap: 10px;
    }

    &__logo {
      margin: 0 auto;
    }

    &__title {
      font-size: 18px;
      text-align: center;
    }

    &__btns {
      grid-template-columns: auto 1fr;
    }
  }
}
