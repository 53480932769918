@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate(-3deg);
    transform: scale3d(.9, .9, .9) rotate(-3deg)
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(3deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(3deg)
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(-3deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(-3deg)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate(-3deg);
    transform: scale3d(.9, .9, .9) rotate(-3deg)
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(3deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(3deg)
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(-3deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(-3deg)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}


.delay-animation(@items, @delay) {
  .loopingClass(0); // Start loop

  .loopingClass(@index) when (@index <= @items) {
    &:nth-child(@{index}) {
      animation-delay: unit((@index * @delay), s);
    }

    .loopingClass(@index + 1); // Increment loop
  }
}
