.video-detail {
  display: grid;
  grid-row-gap: 1rem;

  &__content {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 1rem;

    &__right {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-gap: 1rem;
    }
  }

  &__infos {
    display: grid;
    grid-template-columns: 30% 1fr;
    padding: 2rem;
    background: #f3f3f3;
    border-radius: .5rem;
    grid-gap: 2rem;
  }

  &__title {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 5px;
      background-color: var(--main-color);
      margin-top: 1rem;
    }
  }

  &__header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__4k {
    width: 5rem;
    display: block;
  }

  &__poster {
    height: 0;
    padding-bottom: calc(100% / (5 / 7));
    width: 100%;
    position: relative;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    display: block;

    &:hover {
      .video-detail__poster__cta {
        .translateY(-50%);
        opacity: 1;
      }
    }

    &__cta {
      position: absolute;
      width: calc(100% - 4rem);
      left: 2rem;
      top: 50%;
      opacity: 0;
      .translateY(0%);
      transition: all ease 250ms;
      cursor: pointer;
      text-transform: uppercase;
      padding: 1.25rem 4rem;
      letter-spacing: 1px;
      border-radius: .5rem;
      font-weight: 600;
      border: none;
      line-height: 1;
      font-size: 1.5rem;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
      object-fit: cover;
    }
  }

  &__ctas {
    display: flex;
    margin: 0 -.5rem;
  }

  &__cta {
    width: 100%;
    margin: 0 .5rem;
  }

  &__notation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    [data-stars-container] {
      display: contents;
    }

    &__stars {
      position: relative;

      &:not(:checked) {
        > input {
          position: absolute;
          top: -9999px;
          clip: rect(0, 0, 0, 0);
        }

        > label {
          width: 2.5rem;
          padding: 0 .05em;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
          font-size: 2rem;
          color: black;
          margin-bottom: 0;
          float: right;

          &:before {
            content: @fa-var-star;
            font-weight: 900;
            font-family: 'Font Awesome 5 Pro';
          }

          &:hover, &:hover ~ label {
            color: var(--main-color);
            text-shadow: 0 0 3px var(--main-color);
          }
        }
      }

      > input:checked ~ label {
        color: var(--main-color);
      }

      > label {
        position: relative;
      }
    }

    &__label {
      margin-bottom: 0;
      margin-right: .5rem;
    }

    &__message {
      width: 100%;
      font-weight: bold;

      &--valid {
        font-weight: bold;
        color: @green;
      }

      &--error {
        color: @red;
      }
    }
  }
}

.video-player {
  border-radius: .5rem;
  overflow: hidden;
  position: relative;

  video {
    width: 100%;
    border-radius: .5rem;
  }

  &__poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background: #da002f;
    display: block;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    color: white;
    font-size: 3rem;
    padding: 0;
  }
}

.video-infos {
  &:not(:last-child) {
    border-right: 1px solid #e3e3e3;
  }

  &__item {
    margin: .5rem 0;

    i {
      color: var(--main-color);
      margin-right: 1rem;
    }
  }
}

.confirmation-buy {
  margin-top: 15px;
  margin-bottom: 20px;
  border: 1px solid @green;
  text-align: center;
  color: @green;
  padding: 2rem;
  border-radius: .5rem;
  position: relative;

  &--m18p {
    color: var(--main-color);
    border-color: var(--main-color);

    .confirmation-buy {
      &__link {
        color: var(--main-color);
      }

      &__label {
        background: var(--main-color);
      }
    }
  }

  &__label {
    background: @green;
    color: #f0f0f0;
    position: absolute;
    top: -13px;
    left: 1rem;
    padding: 0.3rem 0.8rem;
  }

  &__link {
    text-decoration: underline;
    font-weight: bold;
  }
}

@media screen and (max-width: 920px) {
  .video-detail {
    &__content {
      grid-template-columns: 1fr;

      &__left {
        display: none;
      }
    }

    &__ctas {
      display: grid;
      grid-row-gap: 1rem;
      margin: 0;
    }

    &__cta {
      margin: 0;
    }

    &__infos {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }

  .video-infos {
    &:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 1rem;
    }
  }
}
