
.footer {
  padding: 2rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &--white {
    background: white;
  }

  &__title {
    color: #757575;
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  &__link {
    color: var(--main-color);
    padding: .25rem 0;
    display: block;
    font-size: 1.5rem;
    margin: 0 -1rem;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__link {
    font-size: 1.2rem !important;
    display: flex;
    text-decoration: underline;
    border-radius: 1rem;
    color: var(--main-color);
    margin: 0;
    padding: 0;
    align-items: center;
  }

  &__text {
    font-size: 1.2rem !important;
    color: black;
    width: 100%;
    font-weight: bold;

    a {
      color: white;
    }
  }
}

