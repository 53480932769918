.dalenys-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  --dalenys-color: #2F81E1;
  display: none;

  &--open {
      display: block;
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
  }

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    max-width: 50rem;
    border-radius: 2rem;
    overflow: hidden;
    background: #141414;
  }

  &__header {
    position: relative;
    padding: 2rem;

    &:before {
      content: '';
      width: 100%;
      z-index: 1;
      background: var(--dalenys-color);
      height: calc(100% - 10rem);
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__img {
    width: 70%;
    margin: 0 auto;
    display: block;
    z-index: 2;
    position: relative;
  }


  &__content {
    margin-top: -10rem;
    backdrop-filter: blur(15px);
    color: white;
    z-index: 3;
    position: relative;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    padding: 3rem;

    strong {
      color: var(--dalenys-color);
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 1;
    margin: 0;
  }

  &__subtitle {
    color: #5d5d5d;
  }

  &__text {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__cta {
    display: block;
    color: white;
    background: #2f80e1;
    border-radius: .5rem;
    padding: 1.5rem;
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }

  &__link {
    color: white;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    &__modal {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      transform: none;
      z-index: 1;
      overflow: auto;
    }

    &__content {
      padding: 1.5rem;
    }
  }
}

.dalenys-banner {
  --dalenys-color: #2F81E1;
  border-radius: 1rem;
  background: var(--dalenys-color);
  color: white;
  padding: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &__header {
    display: flex;

    &:before {
      content: @fa-var-exclamation-triangle;
      font-family: "Font Awesome 5 Pro";
      color: white;
      opacity: .3;
      font-size: 3rem;
      font-weight: bold;
      padding-right: 2.5rem;
    }

    &__text {
      flex: 1;
      display: none;
    }

    &__title {
      font-weight: bold;
    }

    &__subtitle {
      text-decoration: underline;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    line-height: 1.2;
    padding-right: 1rem;
  }

  &__close {
    display: none;
  }

  &__cta {
    background: white;
    color: black;
    padding: 1rem 2rem;
    display: block;
    border-radius: 1rem;
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;

    &__header {
      width: 100%;

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__content {
      flex-direction: column;
      padding: 2rem;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 1rem;
      margin-top: 1rem;
    }

    &__cta {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
      padding: 1rem;
    }

    &__text {
      padding: 0;
      text-align: center;
    }

    &__title {
      font-weight: bold;
      line-height: 1;
    }

    &__subtitle {
      text-decoration: underline;
    }

    &__close {
      display: block;
      color: white;
      padding: .5rem;
    }
  }
}