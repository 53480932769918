.main {
  flex: 1;
  width: 100%;

  &__header {
    display: contents;
  }

  &__content {
    position: relative;
  }

  &__section {
    padding: 3rem 0;

    &--carousel {
      padding: 0;
    }

    &--red {
      background-image: linear-gradient(135deg, #3e000d 0%, #ff0036 100%);
    }

    &--gray {
      background: @gray-95;
    }

    .rd {
      direction: rtl;
      unicode-bidi: bidi-override;
    }
  }

  &--gray {
    background: @gray-95;

    & + .footer {
      background: white;
    }
  }
}

.force-blur {
  filter: blur(30px) !important;
}
