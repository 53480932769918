.tabs {
  &__navbar {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    padding: 0 2rem;

    &__item {
      min-width: 25%;
      margin-bottom: -1px;
      overflow: hidden;
    }

    &__link {
      padding: 1rem;
      display: block;
      color: #bbbbbb;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;


      &--active {
        color: var(--main-color);
        font-weight: bold;
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
        border-radius: .5rem .5rem 0 0;
      }
    }
  }

  &__item {
    display: none;

    &--active {
      display: block;
    }

  }

}

.filters {
  border-radius: 1rem;
  display: block;
  z-index: 1;

  &__selected {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 1rem;

    &-tags {
      display: flex;
      flex-wrap: wrap;
    }

    &-tag {
      margin: 0 .5rem;
      padding: .5rem 1rem;
      background-color: white;
      border-radius: 5px;
      display: block;
      color: black;
      transition: all ease 250ms;

      &:hover {
        box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
      }

      i {
        margin-left: .5rem;
      }
    }
  }

  &--links {
    padding: 0;
    overflow: hidden;
  }

  &__list {
    display: flex;
    align-items: center;

    input::placeholder {
      color: black;
    }

    > * {
      width: 100%;
      padding: 0 1rem;
    }
  }

  &__link {
    padding: 2rem;
    text-align: center;
    color: black;
    position: relative;
    background: white;
    margin: 0 1rem;
    border-radius: 1rem;
    overflow: hidden;

    &:hover {
      background: #e1e1e1;
    }

    &--active:after {
      position: absolute;
      width: 100%;
      content: '';
      height: 5px;
      background: var(--main-color);
      left: 0;
      bottom: 0;
    }
  }
}

.dropdown-filters {
  &__input {
    border: none;
    width: 100%;
    padding: 0;
    background: transparent;
    font-size: 1.2rem;
    letter-spacing: 1px;

    &::placeholder {
      font-weight: 700;
      color:black;
      text-transform: uppercase;
    }
  }
  &__toggle {
    border-radius: 0.5rem;
    width: 100%;
    border: none;
    padding: 1.5rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 1px;
    background: 0 0;
    font-weight: 700;
    outline: none;
    border-bottom: 1px solid @gray-95;
    background: white;

    &:after {
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      display: block;
      border-top-color: @gray;
    }
  }

  .dropdown__content {
    width: 100%;

    form {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    max-height: 25rem;
    overflow: auto;
    background: #fff;
    border: none;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    margin-top: 1rem;
  }

  &__link {
    font-size: 1.2rem;
    padding: 1rem;
    color: @black;
    display: block;
    position: relative;

    &:before {
      content: '\f111';
      font-family: 'font awesome 5 pro';
      font-weight: 400;
      margin-right: 1rem;
    }
  }
}
