.video-item {
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 5/7;
  overflow: hidden;
  border-radius: .5rem;
  transition: all ease 500ms;
  margin-bottom: 1.5rem;
  background: @gray-90;

  &__thumb {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__infos {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: rgba(255,255,255,0.95);
    display: flex;
    flex-direction: column;
    top: 100%;
    padding: 2rem;
    transition: all ease-out 300ms;
  }

  &__title {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    color: black;
    line-height: 1;

    &:after {
      content: '';
      display: block;
      width: 25%;
      height: .2rem;
      background: var(--main-color);
      margin-top: 1rem;
    }
  }

  &__subtitle {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: black;
    font-weight: normal;
  }

  &__play {
    position: relative;
    background: var(--main-color);
    color: white;
    text-align: center;
    padding: 1rem;
    font-weight: 500;
    margin-top: 1rem;
    border-radius: .5rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }

  &__see-more {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    z-index: 1;
    .translateY(-50%);
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
  }

  &:hover {
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%), 0 15px 35px rgb(0 0 0 / 5%);

    .video-item {
      &__infos {
        transform: translateY(-100%);
      }
    }
  }

  &--last {
    .video-item__thumb {
      opacity: .15;
    }
  }

  @media screen and (max-width: 910px) {
    border-radius: 1.5rem;
  }
}
