.get-premium {
  position: relative;

  > a {
    width: 100%;
    display: block;

    > img {
      display: block;
      width: 100%;
      object-fit: contain;
    }
  }
}

.premium-offer-wrapper {
  background: #111;
  margin-bottom: 3rem;
  margin-top: -3rem
}
